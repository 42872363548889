import React from 'react';
import PropTypes from 'prop-types';
import { openTab } from '../../../src/utils/openTab';
import FSFlatButton from '../../../src/components/lib/FSFlatButton';

const CiriumAircraftEmissionsLink = 'https://www.cirium.com/thoughtcloud/cirium-emerald-sky-to-transform-aircraft-emissions-and-fuel-burn-data-accuracy/?cmpid=ilc%7Cairlines-airports%7C|CIU77-04-2024-emeraldsky';

const wordiness = 'Cirium’s EmeraldSky set to transform aircraft emissions and fuel burn data accuracy.';

const AircraftEmissionsMonitor = (props) => {
  const {
    reportButtonPress,
  } = props;

  return (
    <div className='homepage-aem-section'>
      <div className='homepage-aem-content'>
        <h4 className='homepage-aem-text'>{wordiness}</h4>
        <div className='homepage-cirium-button-container'>
          <FSFlatButton
            href={CiriumAircraftEmissionsLink}
            backgroundColor='#00856C'
            label='READ ANNOUNCEMENT'
            onClick={(e) => {
              e.preventDefault();
              reportButtonPress('AircraftEmissionsMonitor');
              openTab(CiriumAircraftEmissionsLink);
            }}
          />
        </div>
      </div>
      <div className='homepage-aem-image' />
    </div>
  );
};

AircraftEmissionsMonitor.propTypes = {
  reportButtonPress: PropTypes.func,
};

export default AircraftEmissionsMonitor;
