import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import FSFlatButton from '../../../src/components/lib/FSFlatButton';
import FSLogo from '../../../static/images/homepage-icons/fs-tailfin.svg';
import { getFSHost } from '../../../src/lib/hostname';

const leftButtons = [
  { label: 'flight tracker', link: '/flight-tracker/search', gaLabel: 'flightTracker', next: true },
  { label: 'current conditions', link: '/airport-conditions/search', gaLabel: 'currentConditons' },
  { label: 'airport delays', link: '/airport-delays', gaLabel: 'airportDelays' },
  // { label: 'global cancellations and delays', link: '/global-cancellations-and-delays', gaLabel: 'globalCancellationDelays' },
  { label: 'on-time performance reports', link: 'https://www.cirium.com/resources/on-time-performance/?cmpid=ilc%7Cotp%7CCIALD_202501_glob_mul%7C2024-OTP-Annual-Report_m&sfid=701dP000008LIGgQAO', gaLabel: 'airlinePerformanceReports', next: true },
];
const rightButtons = [
  { label: 'flight alerts', link: '/flight-alerts', gaLabel: 'flightAlerts' },
  { label: 'historical flight status', link: '/historical-flight', gaLabel: 'historicalFlightStatus' },
  { label: 'departures and arrivals', link: '/airports/departing-arriving', gaLabel: 'departuresArrivals' },
];

const handleClick = (link, dispatch) => dispatch(push(link));

const FeaturesSection = props => (
  <div className='homepage-features-section'>
    <div className='homepage-features-title-content'>
      <h3 className='homepage-features-center-heading'>Welcome to FlightStats</h3>
      <h3 className='homepage-features-center-heading-lower'>
        Explore the many sections of our website and learn why FlightStats is the trusted source for
        your day of travel needs.
      </h3>
    </div>
    <div className='homepage-features-main-content'>
      <div className='homepage-features-col'>
        <h3 className='homepage-features-col-heading homepage-features-col-heading-left'>Free Services That Ease the Day of Travel</h3>
        <div className='homepage-features-button-group'>
          {leftButtons.map((o) => {
            const { gaLabel, label, link, next = false } = o;
            const wrapperClassName = 'homepage-features-button-container';
            if (next) {
              // navigate to the next.js part of the app
              const linkIsHttps = link.startsWith('https://');
              return (
                <div
                  className={wrapperClassName}
                  key={`${label}`}
                >
                  <a
                    href={
                      linkIsHttps ?
                      link :
                      `${getFSHost()}${link}`
                    }
                  >
                    <FSFlatButton
                      label={label}
                      onClick={(e) => {
                        e.preventDefault();
                        props.reportButtonPress(gaLabel);
                        window.location.href = (linkIsHttps ? link : `/v2${link}`);
                      }}
                    />
                  </a>
                </div>
              );
            }
            return (
              <div
                className={wrapperClassName}
                key={`${label}`}
              >
                <FSFlatButton
                  href={`/v2${link}`}
                  label={label}
                  onClick={(e) => {
                    e.preventDefault();
                    props.reportButtonPress(gaLabel);
                    handleClick(link, props.dispatch);
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className='homepage-features-col homepage-features-dynamic-content'>
        <h3 className='homepage-features-center-heading'>Welcome to FlightStats</h3>
        <img src={FSLogo} style={{ width: '100%' }} alt='FlightStats' />
        <h3 className='homepage-features-center-heading-lower'>
          Explore the many sections of our website and learn why FlightStats is the trusted source
          for your day of travel needs.
        </h3>
      </div>
      <div className='homepage-features-col'>
        <h3 className='homepage-features-col-heading'>Premium Tools for the Advanced Travelers</h3>
        <p className='homepage-features-col-subheading'>{props.user && props.user.subscriptionActive ? '' : 'Subscription Required'}</p>
        <div className='homepage-features-button-group'>
          {rightButtons.map(o => (
            <div
              className='homepage-features-button-container'
              key={`${o.label}`}
            >
              <FSFlatButton
                href={`/v2${o.link}`}
                label={o.label}
                onClick={(e) => {
                  e.preventDefault();
                  props.reportButtonPress(o.gaLabel);
                  handleClick(o.link, props.dispatch);
                }}
              />
            </div>
          ))}
        </div>
        <div className={`homepage-features-subscription-box ${props.user && props.user.subscriptionActive && 'homepage-features-user-subscribed'}`}>
          <div className='homepage-features-subscription-lockup'>
            <h3 className='homepage-features-subscription-heading'>subscriptions start at</h3>
            <h4 className='homepage-features-subscription-amount'>$2.99</h4>
          </div>
          <FSFlatButton
            href='/v2/register/plan'
            label='get a subscription'
            onClick={(e) => {
              e.preventDefault();
              props.reportButtonPress('getSubscription');
              handleClick('/register/plan', props.dispatch);
            }}
          />
        </div>
      </div>
    </div>
  </div>
);

FeaturesSection.propTypes = {
  dispatch: PropTypes.func,
  reportButtonPress: PropTypes.func,
  user: PropTypes.object,
};

export default FeaturesSection;
