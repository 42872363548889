import React from 'react';
import PropTypes from 'prop-types';
import { openTab } from '../../../src/utils/openTab';
import FSFlatButton from '../../../src/components/lib/FSFlatButton';

const CiriumLink = 'https://www.cirium.com/resources/on-time-performance/?cmpid=ilc%7Cotp%7CCIALD_202501_glob_mul%7C2024-OTP-Annual-Report_m&sfid=701dP000008LIGgQAO';

const title = 'The On-Time Performance Review 2024';


const CiriumOTPSection = (props) => {
  const {
    reportButtonPress,
  } = props;

  return (
    <div className='homepage-cirium-otp-section'>
      <img src='https://www.cirium.com/wp-content/themes/cirium/assets/img/logo-dark.svg' alt='Cirium logo' className='homepage-otp-cirium-logo' />
      <div className='homepage-cirium-otp-section-left-col'>
        <div className='homepage-cirium-otp-section-text-container'>
          <h3>{title}</h3>
        </div>
        <div className='homepage-cirium-otp-section-left-col-container'>
          <div className='homepage-cirium-otp-section-poster-container'>
            <span className='text'>
              Cirium releases the 2024 On-Time Performance Review for airline and airport operational performance. In addition to the annual Report, Cirium publishes monthly airline and airport OTP Reports.
            </span>
          </div>
          <div className='homepage-cirium-otp-section-button-container'>
            <FSFlatButton
              href={CiriumLink}
              backgroundColor='#00856C'
              label='DOWNLOAD NOW'
              onClick={(e) => {
                e.preventDefault();
                reportButtonPress('CiriumOTP2021');
                openTab(CiriumLink);
              }}
            />
          </div>
        </div>
      </div>
      <div className='homepage-cirium-otp-section-right-col' />
    </div>
  );
};

CiriumOTPSection.propTypes = {
  reportButtonPress: PropTypes.func,
};

export default CiriumOTPSection;
